.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12em;
  min-height: 100vh;
  /* height: 9000px; */
  /* margin-top: 8vh; */
}
